<template>
  <MobileDefault v-if="isMobile">
    <div class="container-bg-white container-space-mobile-default" style="margin-bottom: 0; padding-bottom: 0">
      <div class="flex flex-row justify-space-between items-center">
        <h2 class="title mobile-title">
          <span class="border-left-line mobile-view"></span>
          <span>Sản phẩm bán chạy</span>
        </h2>
<!--        <a-icon type="filter" style="color: #838383"/>-->
        <span class="text-xs">Tỷ giá: 1 CNY = 3.541 VND</span>
      </div>
<!--      <a-button class="relative" type="primary" size="small" ghost-->
<!--                @click="onClickBtnDownload('product')"-->
<!--                :disabled="isLoadingDownloadCSV"-->
<!--                v-if="!isAvailableToDownloadProduct"-->
<!--                style="margin: 10px 0; font-size: 10px; height: 28px;"-->
<!--      >-->
<!--        <a-icon :type="isLoadingDownloadCSV ? 'loading' : 'download'"/>-->
<!--        <span>Download danh sách</span>-->
<!--      </a-button>-->
    </div>
    <TableProductGlobal class="container-bg-white" style="margin: 0 12px 12px 12px" :load-type="null"/>
  </MobileDefault>
  <Default v-else>
    <TableProductGlobal class="container-bg-white" style="margin: 0 12px" :load-type="null"/>
  </Default>
</template>

<script>
import MobileDefault from "@/layout/MobileDefault";
import Default from "@/layout/Default";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import TableProductGlobal from "@/views/search/TableProductGlobal";

export default {
  name: "TableProductGlobalView",
  components: {
    MobileDefault,
    Default,
    TableProductGlobal
  },
  mixins: [
    mixinStoreGetterGlobal
  ],
  data() {
    return {
      isLoadingDownloadCSV: null
    }
  },
  mounted() {
    window.scrollTo(0, 0)
  },
}
</script>

<style scoped>

</style>
